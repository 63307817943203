/* ======= BOOTSTRAP VARIABLES, IMPORT & FONTS ======= */
$primary: #009fe3;
$secondary: #97c11f;
$light: #ebebeb;
$dark: #24262B;
$grey: #464a4e;

$btn-font-weight: 600;
$btn-border-width: 2px;
$btn-border-radius: 0px;
$btn-border-radius-lg: 0px;
$btn-border-radius-sm: 0px;
$card-border-radius: 0px;

$card-border-width:  1px;
$card-border-color:   $light;

$display1-size: 2.8rem !default;
$display2-size: 1.4rem !default;

@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,700,700i,800,800i,900,900i');

@import '../../../node_modules/bootstrap/scss/functions';
@import 'bootstrap-variables';
@import 'font-awesome-variables';

@import '../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/brands';


/* ======= LAYOUT ======= */
#cta {
	background: linear-gradient(rgba(58,136,214,.9), rgba(0,0,0,.9)), url(img/bg-cta.jpg);
	/* Center and scale the image nicely */
	background-size: cover;
	background-position: 50%;
}

/*#intro2 {
	
background: radial-gradient(circle, rgba(2,40,53,.8) 25%, rgba(3,88,117,.6) 65%, rgba(0,159,227,.5) 100%), url(img/intro-hp.jpg);
background-size: cover;
	background-position: 80% 80%;
}*/

#intro2 {
	
background: radial-gradient(circle, rgba(2,40,53,.8) 25%, rgba(3,88,117,.4) 65%, rgba(0,159,227,.2) 100%), url(img/intro-hp.jpg);
background-size: cover;
}

#hero {
	background: linear-gradient(rgba(58,136,214,.7), rgba(0,0,0,.7)), url(img/bg-cta-2.jpg);
	/* Center and scale the image nicely */
	background-size: cover;
}

#contacts {
	background: linear-gradient(rgba(244,246,249,.9), rgba(244,246,249,.7)), url(img/contacts.jpg);
	/* Center and scale the image nicely */
	background-size: cover;
}

/* ======= UTILS ======= */


h1 {
	font-size: 1.8rem !important;
	color: $primary;
	font-weight: 600;
}

h2 {	font-size: 1.4rem !important;}

.h5, h5 {
	font-size: 1.15rem !important;
	color: $primary;
}

.display {
	&-1 {
		font-weight: 700;
		color: #ffffff;
		font-size: 2.2rem !important;
		@include media-breakpoint-up(lg) {
			font-size: 2.3rem !important;
		}
	}
	&-2 {
		font-weight: 400;
		color: #ffffff;
		@include media-breakpoint-up(lg) {
			font-size: 1.7rem;
		}
	}
	&-3 {
		font-size: 1.9rem;
		font-weight: 600;
		color: #ffffff;

		@include media-breakpoint-up(lg) {
			font-size: 1.7rem;
		}
	}
	&-4 {
		font-size: 1rem;
		font-weight: 600;
		text-transform: uppercase;
		@include media-breakpoint-up(lg) {
			font-size: 0.8rem;
			font-weight: 600;
			text-transform: uppercase;
		}
	}
}


.mapmetal {max-width: 63%;}
/* ======= TYPO ======= */

@include media-breakpoint-up(md) {
	html {
		font-size: 1.125rem;
	}
}

.lead {font-weight: 400;}


.card-title {
	color: $primary;
}
.card {background-color: transparent;}
.fa, .fas {
	font-weight: 900;
	color: $primary;
}
.fa-inverse {
	color: #fff !important;
	text-align: center;
}

.btn-link {
	font-weight: 600;
	font-size: 1em;
	@include media-breakpoint-up(lg) {
		font-size: 1.2rem;
	}
}

/*.nav-pills .nav-link, .nav-pills .show>.nav-link {
	text-transform: uppercase;
	font-weight: 600;
	}*/

	.nav-link.active, .nav-link {
		font-weight: 700;
		text-transform: uppercase;
		font-size: 17px;
	}


	.nav-link {
		color: $dark !important;
	}

	.nav-link.active {
		color: $primary !important;
		text-decoration: underline;
	}
/*.nav-link {
	color: $primary !important;
}
*/
/* ======= FOOTER ======= */





/* ======= FORM ======= */

$form_bg: transparent;

$form_div_width: 100%;

$form_label_width: 35%;
$form_label_color: #333;
$form_label_fontsize: 120%;

$form_input_color: #666;
$form_input_bg: #fff;
$form_input_border: 1px solid #ccc;
$form_input_width: 60%;
$form_input_padding: 1% 2%;
$form_input_fontsize: 120%;

$form_input_color_focus: #333;
$form_input_border_focus: 1px solid #999;
$form_input_bg_focus: #fff;

$form_privacy_fontsize: 80%; 

$form_invia_bg: $primary;
$form_invia_bg_hover: $secondary;
$form_invia_color: #fff;
$form_invia_width: auto;
$form_invia_height: auto;
$form_invia_border: 1px solid #fff;
$form_invia_fontsize: 140%;

$form_error_color: red;


#nomesia_form {
	padding: 20px;
	margin: 10px 0;
	clear: both;
	background: $form_bg;
	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}
	input[type="hidden"] {
		display: none !important;
	}
	div {
		padding-bottom: 5px;
		margin: 5px 0px 5px 0;
		float: left;
		width: $form_div_width;
		&#submit_container{
			float: none;
			clear: both;
			margin: 0 auto;
		}
		select {
			width: $form_input_width;
			*width:130px;
		}
	}
	label {
		float: left;
		color: $form_label_color;
		font-size: $form_label_fontsize; 
		display: block;
		width: $form_label_width;
		*width: 100px; /* IE7 vuole necessariamente un valore in pixel */
		&.error {
			display: block;
			width: 90%!important;
			font-size: 80% !important;
			padding: 2px 5px;
			color: $form_error_color;
			background: none;
			border: none;
		}
	}
	textarea, input, select {
		color: $form_input_color;
		background: $form_input_bg;
		border: $form_input_border;
		font-size: $form_input_fontsize;
		padding: $form_input_padding;
		width: $form_input_width;
		*width: 100px;
		float: left;
		display:inline-block;
		-webkit-transition: border linear .2s, box-shadow linear .2s;
		-moz-transition: border linear .2s, box-shadow linear .2s;
		-o-transition: border linear .2s, box-shadow linear .2s;
		transition: border linear .2s, box-shadow linear .2s;
		&:focus	{
			color: $form_input_color_focus;
			border: $form_input_border_focus;
			background: $form_input_bg_focus;
			outline: 0;
			-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
			-moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
		}
	}
	textarea {
		height: 80px;
	}
	#privacy_container, #marketing_container {
		border: none;
		float: left;
		padding: 0 0 5px;
		label{
			float: left;
			font-weight: normal;
			display: block;
			width: 80%;
			font-size: $form_privacy_fontsize;
			line-height: 1.2em;
			clear: none;
		}
		input {
			margin: 5px 20px 0 0;
			padding: 0;
			background: none;
			border: none;
			width: 15px;
			float: left;
		}
	}
	#more_info_container {
		label {
			width: 100%;
			font-size: 110%;
		}
	}
	.button_form {
		background: $form_invia_bg;
		color: $form_invia_color;
		font-weight: bold;
		text-transform: uppercase;
		border: $form_invia_border;
		width: $form_invia_width;
		display: block;
		margin: 15px auto;
		padding: 8px 20px;
		height: $form_invia_height;
		font-size: $form_invia_fontsize;
		float: none;
		cursor: pointer;
		&:hover, &:focus {
			background: $form_invia_bg_hover;
			color: $form_invia_color;
		}
	}
	.checked {
		display: none!important;
	}
	.field_status{
		margin: 0;
		padding: 0;
		&.error{
			display: block;
		}
	}
	span.ast {
		color: red;
	}
}

@media \0screen {
	#nomesia_form textarea:focus, #nomesia_form input:focus, #nomesia_form select:focus{outline: thin dotted;}
}

@include media-breakpoint-down(xs) {

	#nomesia_form label {width: 100% !important;}
	#nomesia_form input {width: 100% !important;}
	#nomesia_form select {width: 100% !important;}
	#nomesia_form textarea {width: 100% !important;}
	#nomesia_form #privacy_container input, #nomesia_form #marketing_container input {width: 5% !important; margin: 0 4% 0 0;}
	#nomesia_form #privacy_container label, #nomesia_form #marketing_container label{width: 91% !important; padding: 0;}
	#nomesia_form .button_form {white-space: normal;}
}


/* ======= BACK TO TOP ======= */

#top {
	position: fixed;
	width: 40px;
	height: 40px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background: #F15F43;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
	#top-arrow {
		-o-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		border-top: 5px solid #fff;
		border-left: 5px solid #fff;
		border-bottom: none;
		border-right: none;
		width: 10px;
		height: 10px;
		margin: 15px auto;
	}
}

